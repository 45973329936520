/* SearchByAddressTab.css */
.form-container {
    padding: 3px;
}

.flex-row {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.autocomplete {
    width: 40%;
    margin-right: 0.5rem;
}

.form-control, .btn-primary {
    margin-right: 0.5rem;
    width: auto;
}

.main-content {
    display: flex;
    height: calc(100vh - 70px);
}

.address-info {
    flex: 1 1 20%;
    padding-right: 0.5rem;
}

.map-container {
    flex: 3 1 80%;
}

.placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.legend-box {
    display: block;
    height: 15px;
    width: 15px;
    border-radius: 15px;
    margin-right: 8px;
}
