.nav-underline .nav-link {
    color: #555;  /* Default text color for tabs */
    border: none; /* Removes default border styles */
}

.nav-underline .nav-link.active {
    color: #000;  /* Text color for active tab */
    border-bottom: 3px solid #007bff;  /* Blue underline for active tab */
    background-color: transparent; /* Transparent background */
}

/* Custom styles for react-tabs and Bootstrap integration */
.react-tabs__tab--selected .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
  }
  
  .react-tabs__tab--selected .nav-link:hover {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
  }
  
  .nav-item .nav-link {
    cursor: pointer;
  }
  
  .nav-item .nav-link:hover {
    background-color: #e9ecef;
  }
  