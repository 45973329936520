.info-window {
    font-family: Arial, sans-serif;
  }
  
  .info-header {
    display: flex;
    justify-content: space-between;
  }
  
  .info-header h4 {
    margin: 0;
  }
  
  .info-date {
    color: orange;
    font-size: small;
  }
  
  .info-row {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
  
  .info-label {
    color: orange;
    margin-right: 5px;
  }
  